import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { forkJoin, map } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { DeliveryDays, DeliveryDaysDto, ShippingAddress, ShippingAddressDto, ShippingAddressValue, ShippingAddressValueDto } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private httpClient = inject(HttpClient);

    getAddresses() {
        return forkJoin([this.getCurrentAddress(), this.getShippingAddresses()]);
    }

    setAddress(value: ShippingAddressValue) {
        const params = new ShippingAddressValueDto(value);
        return this.httpClient.addUserSwitch().post(`api/v1/shipping/addresses/current/code`, params);
    }

    getDeliveryDays(streetCode: string) {
        return this.httpClient
            .addAllSwitches()
            .get<ApiResponse<DeliveryDaysDto>>(`api/v1/street/code/${streetCode}`)
            .pipe(map((response) => new DeliveryDays(response.data)));
    }

    private getShippingAddresses() {
        return this.httpClient
            .addUserSwitch()
            .get<ApiResponse<ShippingAddressDto[]>>(`api/v1/shipping/addresses`)
            .pipe(map((response) => response.data.map((shippingAddressDto) => new ShippingAddress(shippingAddressDto))));
    }

    private getCurrentAddress() {
        return this.httpClient
            .addUserSwitch()
            .get<ApiResponse<ShippingAddressDto>>(`api/v1/shipping/addresses/current`)
            .pipe(map((response) => new ShippingAddress(response.data)));
    }
}
