import { DeliveryDaysDto } from '../models';

export class DeliveryDays {
    disabledWeekDays: number[];

    constructor(dto: DeliveryDaysDto) {
        this.disabledWeekDays = this.mapDisabledWeekDays(dto);
    }

    public isDisabledWeekDay(date: Date): boolean {
        const dayOfWeek = date.getDay();

        return this.disabledWeekDays.includes(dayOfWeek);
    }

    private mapDisabledWeekDays(dto: DeliveryDaysDto): number[] {
        const weekDays: Record<string, boolean> = {
            0: dto.sunday,
            1: dto.monday,
            2: dto.tuesday,
            3: dto.wednesday,
            4: dto.thursday,
            5: dto.friday,
            6: dto.saturday,
        };

        return Object.keys(weekDays)
            .filter((day) => !weekDays[day])
            .map((day) => parseInt(day, 10));
    }
}
