import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs';
import { selectAddresses, selectCurrent, selectLoaded, selectCurrentCode, selectDeliveryDays } from '../selectors/shipping-addresses.selector';
import { shippingAddressesActions } from '../actions/shipping-addresses.actions';
import { DeliveryDays, ShippingAddress, ShippingAddressValue } from '../models';

@Injectable({
    providedIn: 'root',
})
export class ShippingAddressesFacade {
    private readonly store = inject(Store);

    loaded$ = this.store.select(selectLoaded);
    current$ = this.store.select(selectCurrent).pipe(filter((data): data is ShippingAddress => data !== null));
    addresses$ = this.store.select(selectAddresses).pipe(filter((data): data is ShippingAddress[] => data !== null));
    deliveryDays$ = this.store.select(selectDeliveryDays);

    get currentCode() {
        let value: string | null = null;
        this.store
            .select(selectCurrentCode)
            .pipe(
                take(1),
                tap((current) => (value = current)),
            )
            .subscribe();
        return value;
    }

    get deliveryDays(): DeliveryDays | null {
        let value: DeliveryDays | null = null;
        this.store
            .select(selectDeliveryDays)
            .pipe(
                take(1),
                tap((deliveryDays) => (value = deliveryDays)),
            )
            .subscribe();
        return value;
    }

    getShippingAddresses(): void {
        this.store.dispatch(shippingAddressesActions.getAddresses());
    }

    setShippingAddresses(value: ShippingAddressValue): void {
        this.store.dispatch(shippingAddressesActions.setAddress({ value }));
    }

    clear(): void {
        this.store.dispatch(shippingAddressesActions.clear());
    }
}
