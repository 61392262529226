import { createReducer, on } from '@ngrx/store';
import { shippingAddressesActions } from '../actions/shipping-addresses.actions';
import { DeliveryDays, ShippingAddress } from '../models';

export interface ShippingAddressesState {
    loaded: boolean | null;
    current: string | null;
    addresses: ShippingAddress[] | null;
    deliveryDays: DeliveryDays | null;
}

export const initialState: ShippingAddressesState = {
    loaded: null,
    current: null,
    addresses: null,
    deliveryDays: null,
};

export const shippingAddressesReducer = createReducer(
    initialState,
    on(
        shippingAddressesActions.getAddresses,
        (state): ShippingAddressesState => ({
            ...state,
            loaded: null,
        }),
    ),
    on(
        shippingAddressesActions.getAddressesSuccess,
        (state, payload): ShippingAddressesState => ({
            ...state,
            loaded: true,
            current: payload.current.code,
            addresses: payload.items,
        }),
    ),
    on(
        shippingAddressesActions.getAddressesError,
        (state): ShippingAddressesState => ({
            ...state,
            loaded: false,
        }),
    ),
    on(
        shippingAddressesActions.setAddressSuccess,
        (state, payload): ShippingAddressesState => ({
            ...state,
            current: payload.code,
        }),
    ),
    on(
        shippingAddressesActions.getDeliveryDaysSuccess,
        (state, { deliveryDays }): ShippingAddressesState => ({
            ...state,
            deliveryDays,
        }),
    ),
    on(
        shippingAddressesActions.clear,
        (): ShippingAddressesState => ({
            ...initialState,
        }),
    ),
);
