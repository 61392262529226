import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DeliveryDays, ShippingAddress, ShippingAddressValue } from '../models';

export const shippingAddressesActions = createActionGroup({
    source: 'Shipping Addresses',
    events: {
        'Get Addresses': emptyProps(),
        'Get Addresses Success': props<{ items: ShippingAddress[]; current: ShippingAddress }>(),
        'Get Addresses Error': emptyProps(),

        'Set Address': props<{ value: ShippingAddressValue }>(),
        'Set Address Success': props<{ code: string }>(),
        'Set Address Error': emptyProps(),

        'Get Delivery Days': emptyProps(),
        'Get Delivery Days Success': props<{ deliveryDays: DeliveryDays }>(),
        'Get Delivery Days Error': emptyProps(),

        Clear: emptyProps(),
    },
});
