import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ShippingAddressesState } from '../reducers/shipping-addresses.reducer';
import { SHIPPING_ADDRESSES_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<ShippingAddressesState>(SHIPPING_ADDRESSES_FEATURE_KEY);

const selectLoaded = createSelector(selectState, (state: ShippingAddressesState) => state.loaded);
const selectAddresses = createSelector(selectState, (state: ShippingAddressesState) => state.addresses);
const selectDeliveryDays = createSelector(selectState, (state: ShippingAddressesState) => state.deliveryDays);
const selectCurrent = createSelector(selectState, (state: ShippingAddressesState) => {
    return state.addresses?.find((address) => address.code === state.current) || null;
});
const selectCurrentCode = createSelector(selectState, (state: ShippingAddressesState) => {
    return state.current || null;
});

export { selectLoaded, selectAddresses, selectCurrent, selectCurrentCode, selectDeliveryDays };
